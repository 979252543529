<template>
  <div>
    <b-row class="mobHide">
      <b-col>
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="'https://www.youtube.com/embed/' + videos.items[0].id.videoId"
          allowfullscreen
        ></b-embed>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="col-md-4 col-sm-6 col-12"
        v-for="(gallery, index) in videos.items"
        :key="index"
      >
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="'https://www.youtube.com/embed/' + gallery.id.videoId"
          allowfullscreen
        ></b-embed>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "youTube",
  data() {
    return {
      videos: [],
    };
  },
  created() {
    var call = process.env.VUE_APP_YOUTUBE_URL
    apiCall
      .youTubeInsta(call)
      .then(({ data }) => {
        this.videos = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.row {
  padding-bottom: 20px;
}
.col {
  padding-bottom: 20px;
}
@media only screen and (max-width: 480px) {
  .mobHide {
    display: none;
  }
}
</style>
